

.root {
    text-align: left;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.middle {
    margin: 15px 0px !important;
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
}
