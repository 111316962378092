

.root {
    padding: 15px;
    background: #efefef;
    outline: none;
    border: none;
    border-radius: var(--radius);
    width: 100%;
    font-size: 1em;
    color: rgb(68, 68, 68);
}

.root::placeholder {
    color: rgb(155, 155, 155);
}
