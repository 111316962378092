

.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.root > :first-child {
    flex: 2;
    text-align: left;
    padding-right: var(--tight);
}

.root > :last-child {
    flex: 2;
    text-align: right;
}

.title {
    display: block;
}

.sub {
    display: block;
    color: grey;
    font-size: 0.7em;
}
