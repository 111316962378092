

.root {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.rank {
    min-width: 32px;
}

.title {
    display: flex;
    flex-direction: column;
}

.title > :first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.title > :last-child {
    font-size: 12px;
}

.play {
    margin-left: 5px;
}

.expand {
    transition: all 1s;
    background: white;
    max-height: 0px;
    overflow: hidden;
}

.expanded {
    max-height: 400px;
}

.morebutton {
    position: absolute;
    right: 0px;
    bottom: 0px;
}
