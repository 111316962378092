

.up, .down {
    font-size: 0.6em;
    display: flex;
    align-items: center;
}

.up {
    color: green;
}

.down {
    color: red;
}

.allrank {
    color: grey;
    font-weight: initial;
}
