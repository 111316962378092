

.header {
    position: relative;
    height: 25vh;
    background-size: cover;
    background-position: center;
    padding: 15px;
    overflow: hidden;
    border-radius: 6px;
    margin-bottom: 15px;
}

.headerbg {
    position: absolute;
    object-fit: cover;
    filter: blur(4px);
    top: -5%;
    bottom: -5%;
    left: -5%;
    right: -5%;
    width: 110%;
    height: 110%;
}

.headercontent {
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headercontent > h4 {
    z-index: 1;
}

.img {
    flex: 1;
    position: relative;
    width: max-content;
}

.img > div {
    aspect-ratio: 1/1;
    position: relative;
    height: 100%;
    transform: translateX(-50%);
}

.img > div > div {
    height: 100%;
    position: relative;
}

.img > div > div > img {
    box-shadow: 0 0 14px 0 rgba(53,64,82,.5);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    border-radius: 6px;
}

.grid {
    margin-bottom: 8px;
}
