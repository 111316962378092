

.paper {
    width: 100%;
    padding: 20px;
    height: 100%;
}

.entry {
    display: flex;
    justify-content: space-between;
}

.footer {
    padding: 20px;
    width: 100%;
}

.value {
    font-weight: bold !important;
}

.title {
    margin-bottom: 15px;
}

.changepasswordinput {
    margin-bottom: 5px !important;
}

.changepasswordbutton {
    margin-top: 10px;
    margin-left: auto;
    width: 100%;
}
