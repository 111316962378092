
.result {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
}

.result > :last-child {
    font-size: 0.5em;
}

.sep {
    margin: 0px;
    line-height: 15px;
}
