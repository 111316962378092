.root {
    position: relative;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.songs {
    min-height: 50vh;
}

.loader {
    height: 50vh;
}

.loadercontent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadercontent > span {
    margin-left: var(--tight);
    display: block;
}
