

.root {
    position: relative;
    background-color: white;
    text-align: left;
    -webkit-box-shadow: 10px 10px 16px -9px rgba(0,0,0,0.09);
    -moz-box-shadow: 10px 10px 16px -9px rgba(0,0,0,0.09);
    box-shadow: 10px 10px 16px -9px rgba(0,0,0,0.09);
    overflow: hidden;
}

.cover {
    position: relative;
}

.cover > img {
    width: 100%;
}


.bottom {
    display: flex;
}

.play {
    margin-left: auto;
}

.texts {
    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title {
    font-size: 0.9em;
}

.author {
    font-size: 0.7em;
    color: rgb(137, 139, 145);
}
