

.title {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.song {
  margin-bottom: 15px;
}
