

.root {
    position: relative;
    height: 50px;
    padding-top: 100%;
    background-size: cover;
    overflow: hidden;
}

.content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10%;
    background-color: rgba(165, 165, 165, 0.5);
}

.text {
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.desc {
    color: white;
    font-weight: bold;
    font-size: 2em;
}

.title {
    color: white;
    font-size: 1.5em;
}
