

.root {
    text-align: left;
    background-color: #1B2430;
    color: white;
}

.mobileDrawer {
    background-color: #1B2430;
    width: 30vw;
    height: 100vh;
    padding-top: 10vh;
}

.icon {
    color: white;
}

.siderHeader {
    color: white !important;
}

.title {
    color: white;
    margin-left: 15px !important;
}

@media only screen and (max-width: 960px) {
    .siderHeader {
        color: white !important;
    }
    .mobileDrawer {
        width: 70vw;
    }
}
