@import url(https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap);
body {
  margin: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
}

:root {
  --mobile-limit: 960px;
  --tablet: 1600px;
  --header: 6vh;
  --content: calc(100vh - var(--header));
  --radius: 0.25rem;
  --tight: 5px;
  --shadow: 0 0 14px 0 rgba(53,64,82,.05);
}

html {
  background-color: #1B2430;
}

hr {
  background: none;
  border: none;
  border-bottom: solid #D9DBDD 1px;
}

.App {
  box-sizing: border-box;
  background-color: #F6F7FC;
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.test {
  color: rgb(199, 199, 199);
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nolink {
  text-decoration: unset;
  color: unset;
}



.Auth_root__2-oaG {
    width: 30%;
    margin: auto;
    padding: 40px;
}

.Auth_pageHolder__20Rff {
    height: 80vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

@media only screen and (max-width: 1450px) {
    .Auth_root__2-oaG {
        width: calc(100% - 10px);
    }
}

.Auth_title__1HrxS {
    margin-bottom: 50px !important;
}

.Auth_input__3dnNF {
    margin-bottom: 15px;
}

.Auth_underButton__6gB_v {
    margin-top: 8px;
    text-align: right;
}

.Auth_link__3U9Vm {
    color: #1365ff;
    text-decoration: none;
}

.Auth_fields__1-QXA {
    margin-top: 25px;
    margin-bottom: 25px;
}

.Auth_field__2hKkZ {
    text-align: left;
    margin-bottom: 18px;
}

.Auth_field__2hKkZ > :first-child {
    display: block;
    margin-bottom: 10px;
}

.Auth_ys__b0XNn {
    text-transform: capitalize;
}



.FilledInput_root__1Z7zC {
    padding: 15px;
    background: #efefef;
    outline: none;
    border: none;
    border-radius: var(--radius);
    width: 100%;
    font-size: 1em;
    color: rgb(68, 68, 68);
}

.FilledInput_root__1Z7zC::-webkit-input-placeholder {
    color: rgb(155, 155, 155);
}

.FilledInput_root__1Z7zC:-ms-input-placeholder {
    color: rgb(155, 155, 155);
}

.FilledInput_root__1Z7zC::placeholder {
    color: rgb(155, 155, 155);
}



.LoadButton_load__2lLW9 {
    margin-right: 8px;
}



@-webkit-keyframes Home_fadein__3B7Zd {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes Home_fadein__3B7Zd {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes Home_fromtop__2PMu_ {
    0% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes Home_fromtop__2PMu_ {
    0% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@-webkit-keyframes Home_frombottom__2lMLC {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes Home_frombottom__2lMLC {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

.Home_welcome__2Hw7n {
    -webkit-animation: Home_fadein__3B7Zd 1s, Home_fromtop__2PMu_ 1s;
            animation: Home_fadein__3B7Zd 1s, Home_fromtop__2PMu_ 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Home_welcomehidden__O4J_j {
    opacity: 0;
}

.Home_content__1UkNS {
    transition-delay: 250ms;
    -webkit-animation: Home_fadein__3B7Zd 1s, Home_frombottom__2lMLC 1s;
            animation: Home_fadein__3B7Zd 1s, Home_frombottom__2lMLC 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
    min-height: 75vh;
}

.Home_contenthidden__FbH4X {
    min-height: 75vh;
    opacity: 0;
}

.Home_listened__21PyO {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Home_firstleft__9HE-H {
    padding-bottom: 16px;
    margin-right: 8px;
}

.Home_secondleft__Iw_nZ {
    padding-bottom: 16px;
    margin-left: 8px;
}

.Home_divider__1CTcn {
    padding-top: 8px;
    padding-bottom: 8px;
}

@media only screen and (max-width: 1280px) {
    .Home_minHeight__3L9oV {
        min-height: 400px;
    }
}

@media only screen and (max-width: 1280px) {
    .Home_firstleft__9HE-H {
        margin-right: 0px;
    }
    
    .Home_secondleft__Iw_nZ {
        margin-left: 0px;
    }

    .Home_welcome__2Hw7n {
        display: block;
        text-align: center;
    }
}

.Home_username__2GfOP {
    color: #1365ff;
}

.History_root__3_C4o {
    position: relative;
}

.History_title__2bUXu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 20px;
}

.History_songs__2-jXd {
    min-height: 50vh;
}

.History_loader__2Y4uS {
    height: 50vh;
}

.History_loadercontent__3o-gk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.History_loadercontent__3o-gk > span {
    margin-left: var(--tight);
    display: block;
}



.Track_root__lGeDX {
    position: relative;
    background-color: white;
    text-align: left;
    box-shadow: 10px 10px 16px -9px rgba(0,0,0,0.09);
    overflow: hidden;
}

.Track_cover__njApB {
    position: relative;
}

.Track_cover__njApB > img {
    width: 100%;
}


.Track_bottom__1ROUv {
    display: -webkit-flex;
    display: flex;
}

.Track_play__3ou7n {
    margin-left: auto;
}

.Track_texts__Ws8de {
    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Track_title__3vSOa {
    font-size: 0.9em;
}

.Track_author__2IowF {
    font-size: 0.7em;
    color: rgb(137, 139, 145);
}



.Line_root__3Urdc {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    overflow: hidden;
    padding-right: 20px;
    height: 50px;
}

.Line_coverContainer__1ABeU {
    position: relative;
    max-height: 50px;
    max-width: 50px;
}

.Line_cover__ONDg- {
    width: 50px;
    height: 50px;
}

.Line_info__39tCG {
    -webkit-flex: 4 1;
            flex: 4 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    width: 100%;
    text-align: left;
}

.Line_medium__23T8X {
    -webkit-flex: 4 1;
            flex: 4 1;
}

.Line_tiny__2J1hc {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.Line_lastInfo__3DaxK {
    -webkit-flex: 2 1;
            flex: 2 1;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

@media only screen and (max-width: 960px) {
    .Line_info__39tCG {
        -webkit-flex: unset;
                flex: unset;
    }

    .Line_medium__23T8X {
        -webkit-flex: unset;
                flex: unset;
    }
    
    .Line_tiny__2J1hc {
        -webkit-flex: unset;
                flex: unset;
    }
}

.Line_littleArtist__jiYgd {
    font-size: 0.8em;
    text-align: left;
}



.SimpleArtistLine_root__yI33Q {
    text-decoration: none;
    color: unset;
}

.SimpleArtistLine_root__yI33Q:hover {
    text-decoration: underline;
}



.PlayButton_nomargin__kP51z {
  padding: 0px !important;
}



.BasicCard_root__1g-hX {
    text-align: left;
    padding: 20px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
}

.BasicCard_middle__2xenO {
    margin: 15px 0px !important;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}



.BasicChart_paper__1YuGn {
    padding-top: 20px;
    padding-right: 10px;
    height: 100%;
    position: relative;
}

.BasicChart_header__1nszs {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: var(--tight);
}

.BasicChart_header__1nszs > :first-child {
    margin-right: var(--tight);
}

.BasicChart_dialogcontainer__30-QC {
    height: 80vh;
    width: 100%;
    padding-top: 25px;
    padding-right: 25px;
    text-align: center;
    overflow: hidden;
}

@media only screen and (max-width: 960px) {
    .BasicChart_dialogcontainer__30-QC {
        width: 85vw;
    }
}

.BasicChart_nostats__3tUZe {
    height: 100%;
    width: 100%;
    background: lightgrey;
    padding-top: 20px;
    border-radius: var(--radius);
}

.BasicChart_nostats__3tUZe > div {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-top: -20px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}



.FullWidthHeightLoading_root__19jHF {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}



.SimpleLineChart_root__oQWuR {
    height: calc(100% - 40px);
}

.SimpleLineChart_buttons__2J1qQ {
    position: absolute;
    top: 0px;
    right: 0px;
}



.BestSong_root__3J1Hk {
    position: relative;
    height: 50px;
    padding-top: 100%;
    background-size: cover;
    overflow: hidden;
}

.BestSong_content__2YKuP {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10%;
    background-color: rgba(165, 165, 165, 0.5);
}

.BestSong_text__psW_S {
    height: 100%;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.BestSong_desc__3UZKD {
    color: white;
    font-weight: bold;
    font-size: 2em;
}

.BestSong_title__1jzzg {
    color: white;
    font-size: 1.5em;
}



.BestArtist_root__YPJfB {
    position: relative;
    height: 50px;
    padding-top: 100%;
    background-size: cover;
    overflow: hidden;
}

.BestArtist_content__FtTmP {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10%;
    background-color: rgba(165, 165, 165, 0.5);
}

.BestArtist_text__1SZ6R {
    height: 100%;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.BestArtist_desc__3Cu0C {
    color: white;
    font-weight: bold;
    font-size: 2em;
}

.BestArtist_title__1Dg0T {
    color: white;
    font-size: 1.5em;
}



@-webkit-keyframes ShowIfInScreen_fadein__My5rS {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ShowIfInScreen_fadein__My5rS {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ShowIfInScreen_frombottom__14ZGt {
    0% {
        -webkit-transform: translateY(250px);
                transform: translateY(250px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes ShowIfInScreen_frombottom__14ZGt {
    0% {
        -webkit-transform: translateY(250px);
                transform: translateY(250px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

.ShowIfInScreen_root__10o42 {
    -webkit-animation: ShowIfInScreen_frombottom__14ZGt 700ms, ShowIfInScreen_fadein__My5rS 700ms;
            animation: ShowIfInScreen_frombottom__14ZGt 700ms, ShowIfInScreen_fadein__My5rS 700ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.ShowIfInScreen_hidden__30weM {
    opacity: 0;
}


.QuickInterval_root__3YK7F {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: -webkit-min-content !important;
    width: min-content !important;
}

.QuickInterval_select__2qPRc {
    width: -webkit-min-content;
    width: min-content;
}

.QuickInterval_menuItem__p-gEN {
    padding: 0 10px;
}

.QuickInterval_dialog__1EQsY {
    padding: 15px;
}

.QuickInterval_custom__2GK6_ {
    min-width: 2rem !important;
}

.QuickInterval_entry__1S1Mb {
    margin-bottom: 15px;
}


.Divider_divider__2SvrI {
    margin: 20px 0px;
}


.Layout_header__3A3Tc {
    height: var(--header);
    background-color: white;
}

.Layout_appbar__1Y84L {
    height: var(--header);
    -webkit-justify-content: center;
            justify-content: center;
}

.Layout_toolbar__1jBHB {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Layout_page__3Ir2i {
    display: -webkit-flex;
    display: flex;
    max-width: 100vw;
    overflow: hidden;
}

.Layout_siderContainer__2pGoe {
    -webkit-flex: 0 0 250px;
            flex: 0 0 250px;
}

.Layout_sider__hG7gA {
    position: fixed;
    width: 100%;
    width: 250px;
    padding-top: 25px;
    background-color: #1B2430;
    z-index: 4;
    height: var(--content);
    overflow: hidden;
}

.Layout_content__2ZQnQ {
    position: relative;
    -webkit-flex: 1 1;
            flex: 1 1;
    width: 1px;
    padding: 25px;
}

@media only screen and (max-width: 1600px) {
    .Layout_content__2ZQnQ {
        width: 100vw;
        padding: 25px 10px;
    }
    .Layout_siderContainer__2pGoe {
        -webkit-flex: 0 0;
                flex: 0 0;
        width: 0px;
    }
    .Layout_sider__hG7gA {
        width: 0px;
        height: 100vh;
    }
}



.Sider_root__3rNRH {
    text-align: left;
    background-color: #1B2430;
    color: white;
}

.Sider_mobileDrawer__1B8fC {
    background-color: #1B2430;
    width: 30vw;
    height: 100vh;
    padding-top: 10vh;
}

.Sider_icon__2znyV {
    color: white;
}

.Sider_siderHeader__30vZQ {
    color: white !important;
}

.Sider_title__2Y8n7 {
    color: white;
    margin-left: 15px !important;
}

@media only screen and (max-width: 960px) {
    .Sider_siderHeader__30vZQ {
        color: white !important;
    }
    .Sider_mobileDrawer__1B8fC {
        width: 70vw;
    }
}


:root {
    --search-bar-closed: 20vw;
    --search-bar-open: 30vw;
    --search-bar-results: 30vw;
}

.SearchBar_inputcontainer__1qVQb {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    background: rgba(255, 255, 255, 0.137);
}

.SearchBar_inputcontainer__1qVQb:first-child {
    padding-left: 8px;
}

.SearchBar_inputcontainer__1qVQb:focus-within, .SearchBar_inputcontainer__1qVQb:hover {
    background: rgba(255, 255, 255, 0.320);
}

.SearchBar_input__1-DF_ {
    color: white;
    width: 20vw;
    width: var(--search-bar-closed);
    border: none;
    outline: none;
    padding: 8px 8px;
    background: none;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size: medium;
    font-size: initial;
}

.SearchBar_input__1-DF_:focus {
    width: 30vw;
    width: var(--search-bar-open);
}

.SearchBar_input__1-DF_::-webkit-input-placeholder {
    -webkit-transition: all 250ms;
    transition: all 250ms;
    color: rgba(255, 255, 255, 0.884);
    font-size: medium;
    font-size: initial;
    overflow: visible;
    text-align: center;
    /* width: 100% !important; */
}

.SearchBar_input__1-DF_:-ms-input-placeholder {
    -ms-transition: all 250ms;
    transition: all 250ms;
    color: rgba(255, 255, 255, 0.884);
    font-size: medium;
    font-size: initial;
    overflow: visible;
    text-align: center;
    /* width: 100% !important; */
}

.SearchBar_input__1-DF_::placeholder {
    transition: all 250ms;
    color: rgba(255, 255, 255, 0.884);
    font-size: medium;
    font-size: initial;
    overflow: visible;
    text-align: center;
    /* width: 100% !important; */
}

.SearchBar_input__1-DF_:focus::-webkit-input-placeholder {
    /* width: 0% !important; */
}

.SearchBar_input__1-DF_:focus:-ms-input-placeholder {
    /* width: 0% !important; */
}

.SearchBar_input__1-DF_:focus::placeholder {
    /* width: 0% !important; */
}

.SearchBar_paper__1hnEL {
    width: 30vw;
    width: var(--search-bar-results);
    border-radius: 6px;
    padding: 10px;
}

.SearchBar_result__BsPs_ {
    padding: 10px;
    border-radius: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-decoration: none;
    color: unset;
}

.SearchBar_result__BsPs_:hover {
    background: lightgrey;
}

.SearchBar_result__BsPs_ > img {
    margin-right: 5px;
    height: 32px;
    width: 32px;
    border-radius: 6px;
}

.SearchBar_result__BsPs_ > div {
    color: grey;
}

.SearchBar_resultsnb__3TWQE {
    text-align: center;
    padding-left: 10px;
    color: grey;
}

@media only screen and (max-width: 960px) {
    :root {
        --search-bar-closed: 60vw;
        --search-bar-open: 60vw;
        --search-bar-results: 60vw;
    }
}



.Settings_paper__BNz4s {
    width: 100%;
    padding: 20px;
    height: 100%;
}

.Settings_entry__1BcKO {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Settings_footer__3Glez {
    padding: 20px;
    width: 100%;
}

.Settings_value__32USn {
    font-weight: bold !important;
}

.Settings_title__-mSuR {
    margin-bottom: 15px;
}

.Settings_changepasswordinput__3I5gr {
    margin-bottom: 5px !important;
}

.Settings_changepasswordbutton__VzY5e {
    margin-top: 10px;
    margin-left: auto;
    width: 100%;
}



.SettingField_root__3fkdG {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.SettingField_root__3fkdG > :first-child {
    -webkit-flex: 2 1;
            flex: 2 1;
    text-align: left;
    padding-right: var(--tight);
}

.SettingField_root__3fkdG > :last-child {
    -webkit-flex: 2 1;
            flex: 2 1;
    text-align: right;
}

.SettingField_title__CcC7v {
    display: block;
}

.SettingField_sub__1TIfe {
    display: block;
    color: grey;
    font-size: 0.7em;
}



.LogToSpotify_root__xkT6k {
    height: 95vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: -50px;
}

.LogToSpotify_entry__2M9hx {
    margin-bottom: 25px;
    display: block;
    position: relative;
}

.LogToSpotify_entry__2M9hx > img {
    width: 20%;
}

.LogToSpotify_desc__2z08m {
    width: 50%;
    margin: auto;
}



.AllStats_graph__3ZygJ {
    min-height: 350px;
}

.AllStats_title__2jMS4 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 20px;
}

.PercentChart_root__1av4p {
    height: calc(100% - 40px);
}



.Artist_header__1k8cq {
    position: relative;
    height: 25vh;
    background-size: cover;
    background-position: center;
    padding: 15px;
    overflow: hidden;
    border-radius: 6px;
    margin-bottom: 15px;
}

.Artist_headerbg__28Te7 {
    position: absolute;
    object-fit: cover;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    top: -5%;
    bottom: -5%;
    left: -5%;
    right: -5%;
    width: 110%;
    height: 110%;
}

.Artist_headercontent__3ox2Q {
    height: 100%;
    width: 100%;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Artist_headercontent__3ox2Q > h4 {
    z-index: 1;
}

.Artist_img__1WMn0 {
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
    width: -webkit-max-content;
    width: max-content;
}

.Artist_img__1WMn0 > div {
    aspect-ratio: 1/1;
    position: relative;
    height: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.Artist_img__1WMn0 > div > div {
    height: 100%;
    position: relative;
}

.Artist_img__1WMn0 > div > div > img {
    box-shadow: 0 0 14px 0 rgba(53,64,82,.5);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    border-radius: 6px;
}

.Artist_grid__lvq2g {
    margin-bottom: 8px;
}



.Rank_up__1dF6b, .Rank_down__pmMWW {
    font-size: 0.6em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.Rank_up__1dF6b {
    color: green;
}

.Rank_down__pmMWW {
    color: red;
}

.Rank_allrank__Jqru0 {
    color: grey;
    font-weight: normal;
    font-weight: initial;
}


.BestPeriod_down__2xQvg {
    font-size: 0.6em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.BestPeriod_percent__w22y0 {
    margin-left: 5px;
    font-size: 0.5em;
    color: grey;
}


.FirstLast_result__5q_II {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 0.8em;
}

.FirstLast_result__5q_II > :last-child {
    font-size: 0.5em;
}

.FirstLast_sep__1etxN {
    margin: 0px;
    line-height: 15px;
}



.MostListened_root__2qQ9p {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.MostListened_container__eu9aH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-bottom: 5px;
}

.MostListened_rank__1tkP8 {
    min-width: 32px;
}

.MostListened_title__1zkTi {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.MostListened_title__1zkTi > :first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.MostListened_title__1zkTi > :last-child {
    font-size: 12px;
}

.MostListened_play__2_YNI {
    margin-left: 5px;
}

.MostListened_expand__1w269 {
    transition: all 1s;
    background: white;
    max-height: 0px;
    overflow: hidden;
}

.MostListened_expanded__227a- {
    max-height: 400px;
}

.MostListened_morebutton__LonwK {
    position: absolute;
    right: 0px;
    bottom: 0px;
}



.TopSongs_title__3bo2I {
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.TopSongs_song__3THwc {
  margin-bottom: 15px;
}



.components_root__2tNM7 {
}

.components_cover__4B8ib {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}

.components_root__2tNM7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.components_root__2tNM7 > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.components_rank__4dhdN {
  width: 40px;
}

.components_small__3C4Ik {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.components_normal__CdECE {
  text-align: left;
  -webkit-flex: 3 1;
          flex: 3 1;
}

@media only screen and (max-width: 960px) {
  .components_normal__CdECE {
      -webkit-flex: 2 1;
              flex: 2 1;
  }
}

.components_count__2gvN3 {
  text-align: right;
  margin-right: 15px;
  width: 100px;
}

.components_littleArtist__2EL5J {
  font-size: 0.8em;
}

.components_percent__3tXK0 {
  color: grey;
}



.TopArtists_title__oZUUV {
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.TopArtists_artist__3e_m5 {
  margin-bottom: 15px;
}



.TopAlbums_title__3SfIO {
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.TopAlbums_album__3ko28 {
  margin-bottom: 15px;
}

