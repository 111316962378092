

.header {
    height: var(--header);
    background-color: white;
}

.appbar {
    height: var(--header);
    justify-content: center;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page {
    display: flex;
    max-width: 100vw;
    overflow: hidden;
}

.siderContainer {
    flex: 0 0 250px;
}

.sider {
    position: fixed;
    width: 100%;
    width: 250px;
    padding-top: 25px;
    background-color: #1B2430;
    z-index: 4;
    height: var(--content);
    overflow: hidden;
}

.content {
    position: relative;
    flex: 1;
    width: 1px;
    padding: 25px;
}

@media only screen and (max-width: 1600px) {
    .content {
        width: 100vw;
        padding: 25px 10px;
    }
    .siderContainer {
        flex: 0 0 0;
        width: 0px;
    }
    .sider {
        width: 0px;
        height: 100vh;
    }
}
