

.graph {
    min-height: 350px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
