
.down {
    font-size: 0.6em;
    display: flex;
    align-items: center;
}

.percent {
    margin-left: 5px;
    font-size: 0.5em;
    color: grey;
}
