

.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding-right: 20px;
    height: 50px;
}

.coverContainer {
    position: relative;
    max-height: 50px;
    max-width: 50px;
}

.cover {
    width: 50px;
    height: 50px;
}

.info {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    width: 100%;
    text-align: left;
}

.medium {
    flex: 4;
}

.tiny {
    flex: 1;
}

.lastInfo {
    flex: 2;
    justify-content: flex-end;
}

@media only screen and (max-width: 960px) {
    .info {
        flex: unset;
    }

    .medium {
        flex: unset;
    }
    
    .tiny {
        flex: unset;
    }
}

.littleArtist {
    font-size: 0.8em;
    text-align: left;
}
