

.root {
    text-decoration: none;
    color: unset;
}

.root:hover {
    text-decoration: underline;
}
